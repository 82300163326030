import { OnInit, Component } from '@angular/core';



@Component({
    selector: 'jhi-dashboard.component',
    templateUrl: './dashboard.component.html',
    
})

export class DashboardComponent implements OnInit {


    constructor(){
        
    }

    ngOnInit() {
       
    }
}