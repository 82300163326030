import { Component, OnInit, Renderer2 } from '@angular/core';
//import { Location } from '@angular/common';
import { LoginService, AccountService, Account } from 'app/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { ClientAdminService } from 'app/clientAdmin/client-admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { HomeService } from 'app/home';
import { DOMAIN } from 'app/app.constants';

@Component({
    selector: 'admin-home',
    templateUrl: './administration.component.html',
    styles: ['']
})
//@Pipe({ name: 'assetUrl' })
export class AdministrationComponent implements OnInit {
    account: Account;
    isNavbarCollapsed: boolean;
    clientList = [];
    user = {};
    username: string;
    clientId = null;
    isError = false;
    token: string;
    ipAddress: string = '2231.1313.1313';
    ccprimebreadcrumbList = [{ label: `Dashboard`, routerLink: [''] }];
    homeiconccprimebreadcrumb = { icon: 'pi pi-home' };

    constructor(
        private accountService: AccountService,
       // private _location: Location,
      //  private clientAdminService: ClientAdminService,
        private loginService: LoginService,
        private modalService: NgbModal,
        private homeService: HomeService,
        private keycloakService: KeycloakService,
        private router: Router,
        private route: ActivatedRoute,
        private renderer: Renderer2
    ) {}

    async ngOnInit() {
        sessionStorage.setItem('homePageLoaded', 'true');
        this.renderer.removeClass(document.body, 'overflow-scroll');
        this.username = this.keycloakService.getUsername();
        this.token = localStorage.getItem('token');
        this.accountService.identity().then((account: Account) => {
            this.account = account;
        });
        let observable = this.homeService.getUserData(this.username);
        observable.subscribe(resp => {
            this.user = resp;
        });
    }

    transform(value: string): string {
        return this.assetUrl(value);
    }
    assetUrl(url: string): string {
        // @ts-ignore
        const publicPath = __webpack_public_path__;
        const publicPathSuffix = publicPath.endsWith('/') ? '' : '/';
        const urlPrefix = url.startsWith('/') ? '' : '/';

        return `${publicPath}${publicPathSuffix}assets${urlPrefix}${url}`;
    }

    isAuthenticated() {
        return this.accountService.isAuthenticated();
    }

    collapseNavbar() {
        this.isNavbarCollapsed = true;
    }

    login() {
        this.loginService.login();
    }

    navigateTo(path) {
        this.router.navigate([path, {}], { skipLocationChange: true });
    }

    changeClient() {
        return;
    }

   
    backFunction() {
        sessionStorage.setItem('dashboard', 'true');
        window.location.href = DOMAIN;
    }

    itemClicked(event) {
        if (event.item.icon == 'pi pi-home') {
            sessionStorage.removeItem('urlRedirectedTo');
            sessionStorage.setItem('dashboard', 'true');
            window.location.href = `${DOMAIN}/#/nexera/dashboard/home`;
        }
    }
}
