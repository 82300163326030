import './vendor.ts';

import { NgModule, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { NgbDatepickerConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { NgJhipsterModule } from 'ng-jhipster';
import { AuthExpiredInterceptor } from './blocks/interceptor/auth-expired.interceptor';
import { ErrorHandlerInterceptor } from './blocks/interceptor/errorhandler.interceptor';
import { NotificationInterceptor } from './blocks/interceptor/notification.interceptor';
import { SemSharedModule } from 'app/shared';
import { SemCoreModule } from 'app/core';
import { SemAppRoutingModule } from './app-routing.module';
import { SemHomeModule } from './home/home.module';
//import { SemEntityModule } from './entities/entity.module';
import * as moment from 'moment';
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { JhiMainComponent, FooterComponent, PageRibbonComponent, ErrorComponent } from './layouts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// adding imports
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializer } from './utils/app-init';
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LoadingIndicatorService } from 'app/services/loading-indicator.service';
import { NgxSpinnerModule } from 'ngx-spinner';
//import { NgSelectModule } from '@ng-select/ng-select';
//import { UserManagementModule } from './user-management/user-management.module';
import { AuthGuard } from './guards/authguard.service';
import { AdministrationHome } from './administrationHomePage';
import { PagerService } from './pager.service';
//import { ClientAdminModule } from './clientAdmin/client-admin.module';
//import { ClientModule } from './Client';
//import { ApmService } from '@elastic/apm-rum-angular';
//import { ComponentMappingModule } from './componentMapping/component.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DynamicUserModule } from './dashboard/dynamicUserCreation/dynamicUser.module';
import { ReactiveFormsModule } from '@angular/forms';
//import { ApplicationOnboardModule } from './dashboard/applicationOnboard/application-onboard.module';
//import { PrimeModule } from './primeng/primecomponent.module';
//import { ModelsModule } from './onBoard/onBoard.module';
//import { MonacoEditorModule } from 'ngx-monaco-editor';
//import { AppManagementModule } from './appManagement/appManagement.module';
//import { ParentModule } from './customTest/parent.module';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { EncryptDecryptInterceptor } from './blocks/interceptor/EncryptDecrypt.interceptor';
import { RequestLoaderInterceptor } from './blocks/interceptor/request-loader.interceptor';
import { TableModule } from 'primeng/table';
//LicenseManager.setLicenseKey('Crisil_Ltd.__MultiApp_5Devs18_January_2020__MTU3OTMwNTYwMDAwMA==19dc2a08fde765709fa5823bb8849a7a');

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        //NgMultiSelectDropDownModule.forRoot(),
        NgbModule,
      
        SemSharedModule.forRoot(),
        SemCoreModule,
        SemHomeModule,
        AdministrationHome,
      //  ModelsModule,
        NgxSpinnerModule,
        //UserManagementModule,
        //NgSelectModule,
        //ComponentMappingModule,
        DashboardModule,
        DynamicUserModule,
       // ApplicationOnboardModule,
       // ClientAdminModule,
        //ClientModule,
        // ClientUserModule,
        //ParentModule,
        //PrimeModule,
        //AppManagementModule,
        // jhipster-needle-angular-add-module JHipster will add new module here
        //SemEntityModule,
        KeycloakAngularModule,
        SemAppRoutingModule,
        TableModule
        //MonacoEditorModule.forRoot()
    ],
    declarations: [JhiMainComponent, ErrorComponent, PageRibbonComponent, FooterComponent, EmptyRouteComponent],
    providers: [
        LoadingIndicatorService,
        LocalStorageService,
        SessionStorageService,
      
        AuthGuard,
        PagerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestLoaderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EncryptDecryptInterceptor,
            multi: true
        },
        { provide: APP_BASE_HREF, useValue: '/usermanagement/' }
    ],
    exports: [],
    bootstrap: [JhiMainComponent]
})
export class SemAppModule {
    constructor(
        private dpConfig: NgbDatepickerConfig,
        public router: Router /* protected route: ActivatedRoute,
        @Inject(ApmService) service: ApmService */
    ) {
        this.dpConfig.minDate = { year: moment().year() - 100, month: 1, day: 1 };
        // API is exposed through this apm instance
    }

    // constructor(@Inject(ApmService) service: ApmService) {
}
