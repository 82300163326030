import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { NgbDateMomentAdapter } from './util/datepicker-adapter';
import { SemSharedLibsModule, SemSharedCommonModule, HasAnyAuthorityDirective } from './';
//import { HighchartsChartModule } from 'highcharts-angular';
//import { YearPickerComponent } from 'app/yearPicker/year-picker.component';
//import { CustomMultiSelectComponent } from 'app/custom-multi-select/custom-multi-select.component';
import { NgInitDirective } from './NgInitDirective';
import { PrimeNGCommonModule } from './primeng-common.module';
import { AccessFilterCreationComponent } from 'app/utils/accessFilterTree/accessFilterCreation.component';
//import { AgGridModule } from 'ag-grid-angular';
import { ModuleWithProviders } from "@angular/core";
@NgModule({
    imports: [SemSharedLibsModule, SemSharedCommonModule, PrimeNGCommonModule
      //  AgGridModule.forRoot([AccessFilterCreationComponent])
    ],
    declarations: [
        AccessFilterCreationComponent,
        HasAnyAuthorityDirective,
        //YearPickerComponent,
        //CustomMultiSelectComponent,
        NgInitDirective
    ],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateMomentAdapter }],
    exports: [
        SemSharedCommonModule,
        HasAnyAuthorityDirective,
       // HighchartsChartModule,
        //YearPickerComponent,
        //CustomMultiSelectComponent,
        NgInitDirective,
        PrimeNGCommonModule,
        AccessFilterCreationComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SemSharedModule {
    static forRoot(): ModuleWithProviders<SemSharedModule> {
    return {
        ngModule: SemSharedModule
    };
}
}
