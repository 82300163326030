<div class="layout-content">
    <div class="form-row form-start">
        <div class="p-col-12 p-md-12">
            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="isSuccess == true">
                <i class="pi pi-check" style="font-size: 1em" aria-hidden="true"></i> {{ displaySuccessMessage }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isSuccess = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="p-col-12 p-md-12">
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="isFailure == true">
                <i class="pi pi-exclamation-triangle" style="font-size: 1em" aria-hidden="true"></i> {{ displayFailureMessage }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isFailure = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>

    <div class="row mb-1">
        <div class="col-md-6">
            <h1 class="page-heading ml-1 mt-2">
                <strong class="title-s ml-1 color9" routerLink=""> User Management </strong>
                <i class="fa-angle-right-icon ml-1  rightangleicon" aria-hidden="true"></i>
                <strong class="title-s ml-1 bolder"> App Data </strong>
            </h1>
        </div>
        <div class="col-md-12">
            <div class="content-divider"></div>
        </div>
    </div>

    <!---table Start-->

    <div class="row ">
        <div class="table-start table-responsive">
            <p-table
                *ngIf="columns"
                #dt
                [columns]="columns"
                [value]="records"
                [lazy]="true"
                [paginator]="true"
                [rows]="10"
                [totalRecords]="totalSize"
            >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="dummyHeader" *ngFor="let header of headers; let i = index" [hidden]="header == 'Id'">{{ header }}</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td *ngFor="let column of columns; let i = index" [hidden]="column == 'id'">
                            {{ rowData[column] }}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
