<div class="layout-content customheight">

    <div class="row">
        <div class="col-md-6">
            <h1 class="page-heading mt-2">

                <img src="../../content/images/svg/administrtion-heading.png" alt="sem-icons">
                <strong> Aggregator Framework Dashboard </strong></h1>
            <label class="title text-dark">Select a task below</label>
            <jhi-alert></jhi-alert>


        </div>

        <div class="col-md-6"> </div>
        <!--use this for right side button-->
        <div class="col-md-12">
            <div class="content-divider"></div>
        </div>

        <!--hearder-title end-->
        <!--Scenario Analysis start-->

        <div class="row col-md-12 mt-5 pr-0">
            <div class="col-sm-3 mb-3 pl-0 pl-0">
                <div class="card cri-cards">
                    <div class=" d-flex flex-row admindashboardcard" routerLink="/integration-component">
                        <div class="p-2">
                            <img src="../../content/images/svg/client-admin.png" alt="img">
                        </div>
                        <div class="p-2" class="nav-link admindashboardtext"><strong>Integration Component</strong>
                        </div>
                    </div>
                </div>
            </div>




            <div class="col-sm-3 mb-3 pl-0 pl-0">
                <div class="card cri-cards">
                    <div class=" d-flex flex-row admindashboardcard" routerLink="/component-list">
                        <div class="p-2">
                            <img src="../../content/images/svg/client-admin.png" alt="img">
                        </div>
                        <div class="p-2" class="nav-link admindashboardtext"><strong> Aggregration Framework </strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 mb-3 pl-0 pl-0">
                <div class="card cri-cards">
                    <div class=" d-flex flex-row admindashboardcard" routerLink="/server-details-List">
                        <div class="p-2">
                            <img src="../../content/images/svg/client-admin.png" alt="img">
                        </div>
                        <div class="p-2" class="nav-link admindashboardtext"><strong> Environment Details </strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 mt-5 pr-0">
        </div>
        <!--Time Series Analysis row End-->
    </div>
    <!--mainrow end-->
</div>