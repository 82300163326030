import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SemSharedModule } from 'app/shared';
//import { NgSelectModule } from '@ng-select/ng-select';
import { DynamicUserComponent } from './dynamicUser.component';
//import { AgGridModule } from 'ag-grid-angular';
import { DynamicUserRoutingModule } from './dynamicUser.route';
import { ChipsModule } from 'primeng/chips';
import { ReactiveFormsModule } from '@angular/forms';
import { UserCreationComponent } from './userCreation.component';
import { AccessFilterComponent } from './accessFilter.component';
import { RoleScopeMappingComponent } from './roleScopeMapping.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ToastModule } from 'primeng/toast';

@NgModule({
    declarations: [DynamicUserComponent, UserCreationComponent, AccessFilterComponent, RoleScopeMappingComponent],
    imports: [
        SemSharedModule,
        DynamicUserRoutingModule,
        ReactiveFormsModule,
       // NgSelectModule,
        ChipsModule,
        ToastModule,
       // AgGridModule.forRoot([DynamicUserComponent]),
        BreadcrumbModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DynamicUserModule {}
