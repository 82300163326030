<ngx-spinner
bdColor = "rgba(51, 51, 51, 0.8)"
size = "default"
color = "#f8f9fa"
type = "line-scale-pulse-out-rapid"
></ngx-spinner>
 <jhi-page-ribbon></jhi-page-ribbon>
<div>
    <router-outlet name="navbar"></router-outlet>
</div>
<section class="layout-content-wrapper">
      <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
</section>
    