import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppLoaderService {
    loaderStack: string[] = [];
    loaderClassName: string = 'overlay-loader';
    showClassName: string = 'show';

    constructor() {}

    showPageLoader() {
        if (!this.loaderStack.length) {
            document.getElementById(this.loaderClassName).classList.add(this.showClassName);
        }
        this.loaderStack.push(this.showClassName);
    }

    hidePageLoader() {
        if (this.loaderStack.length) {
            this.loaderStack.pop();
        }
        if (!this.loaderStack.length) {
            document.getElementById(this.loaderClassName).classList.remove(this.showClassName);
        }
    }
}
