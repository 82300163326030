import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { NgJhipsterModule } from 'ng-jhipster';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
//import { CookieModule } from 'ngx-cookie';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModuleWithProviders } from "@angular/core";
@NgModule({
    imports: [NgbModule, InfiniteScrollModule, FontAwesomeModule],
    exports: [FormsModule, CommonModule, NgbModule, InfiniteScrollModule, FontAwesomeModule]
})
export class SemSharedLibsModule {
    static forRoot(): ModuleWithProviders<SemSharedLibsModule> {
    return {
        ngModule: SemSharedLibsModule
    };
}
}
