import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TreeTableModule } from 'primeng/treetable';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { CardModule } from 'primeng/card';
import { ListboxModule } from 'primeng/listbox';
import { PanelModule } from 'primeng/panel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DragDropModule } from 'primeng/dragdrop';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OrderListModule } from 'primeng/orderlist';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import {ToggleButtonModule} from 'primeng/togglebutton';
@NgModule({
    imports: [
        ButtonModule,
        TooltipModule,
        RadioButtonModule,
        InputSwitchModule,
        TableModule,
        PaginatorModule,
        InputTextModule,
        TreeTableModule,
        CheckboxModule,
        DropdownModule,
        BrowserAnimationsModule,
        BrowserModule,
        ConfirmDialogModule,
        SelectButtonModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        ListboxModule,
        PanelModule,
        InputTextareaModule,
        CalendarModule,
        AccordionModule,
        TabViewModule,
        CardModule,
        InputNumberModule,
        TabMenuModule,
        KeyFilterModule,
        DragDropModule,
        AutoCompleteModule,
        DialogModule,
        OrderListModule,
        StepsModule,
        ToggleButtonModule
    ],
    exports: [
        ButtonModule,
        TooltipModule,
        RadioButtonModule,
        InputSwitchModule,
        TableModule,
        PaginatorModule,
        InputTextModule,
        TreeTableModule,
        CheckboxModule,
        DropdownModule,
        BrowserAnimationsModule,
        BrowserModule,
        ConfirmDialogModule,
        SelectButtonModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        CardModule,
        InputNumberModule,
        ListboxModule,
        PanelModule,
        InputTextareaModule,
        CalendarModule,
        AccordionModule,
        TabViewModule,
        TabMenuModule,
        KeyFilterModule,
        DragDropModule,
        AutoCompleteModule,
        DialogModule,
        OrderListModule,
        StepsModule,
        ToggleButtonModule
    ]
})
export class PrimeNGCommonModule {}
