export class DynamicUser {

    firstName : string;
    lastName : string;
    middleName : string;
    roles : [];
    groups : [];
    subGroups : [];
    id : number;
    comments : string;
    columnName : string;
}