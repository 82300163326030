import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { USER_MANAGEMENT_SERVER_API_URL } from 'app/app.constants';

@Injectable()
export class HomeService {
    constructor(private http: HttpClient) {}

    getHomePageInformation(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/home/getHomePageInformation', {});
    }

    /* getUserData(username): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/clientUser/getUserData', username);
    } */

    getUserData(username): Observable<any> {
     //   return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/clientUser/getUserData', username);
     return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/hiearachy/getAllUserDetails', username);
    }
    

}
