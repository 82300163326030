import { Component } from '@angular/core';
import { DynamicUserService } from './dynamicUser.service';
import { ALERT_MSG_TIME_OUT } from 'app/constants';

@Component({
    selector: 'access-filters',
    templateUrl: './accessFilter.component.html',
    styleUrls: ['./dynamicUser.component.css']
})
export class AccessFilterComponent {
    columns: any[] = [];
    records: any;
    isSuccess: boolean;
    isFailure: boolean;
    displayFailureMessage: string;
    displaySuccessMessage: string;
    headers: any[] = [];
    totalSize: any;
    constructor(private dynamicUserService: DynamicUserService) {}

    ngOnInit() {
        this.dynamicUserService.getFiltersMappedAppData().subscribe(
            response => {
                this.columns = response['COLUMNS'];
                this.headers = response['HEADERS'];
                this.records = response['Filter_Mapped_Data'];
            },
            response => {
                this.showErrorValidations(true, response.console.error());
            }
        );
    }

    showSuccessValidations(showMessage: boolean, displayValidationMessage: string) {
        this.isFailure = false;
        this.isSuccess = showMessage;
        document.documentElement.scrollTop = 0;
        this.displaySuccessMessage = displayValidationMessage;
        setTimeout(() => {
            this.isSuccess = false;
            this.displaySuccessMessage = '';
        }, ALERT_MSG_TIME_OUT);
    }

    showErrorValidations(showMessage: boolean, displayValidationMessage: string) {
        this.isSuccess = false;
        this.isFailure = showMessage;
        document.documentElement.scrollTop = 0;
        this.displayFailureMessage = displayValidationMessage;
        setTimeout(() => {
            this.isFailure = false;
            this.displayFailureMessage = '';
        }, ALERT_MSG_TIME_OUT);
    }
}
