import { UserRouteAccessService } from 'app/core';
import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';

export const HOME_ROUTE: Routes = [
    {
        path: 'administrationHome/dashboard',
        component: DashboardComponent,
        canActivate: [UserRouteAccessService],
        data: {
            authorities: [],
            pageTitle: ' Dashboard'
        }
    }
];
