export const environment = {
    production: false,

    //USER_MANAGEMENT_SERVER_API_URL: 'http://172.21.152.251:32003/',
    USER_MANAGEMENT_SERVER_API_URL: 'BASE_URL_CC' + 'usermanagement/service/',

    KEY_CLOAK_REALM_NAME: 'KEY_CLOAK_REALMNAME',
    KEY_CLOAK_URL: 'KEY_CLOAK_ISSUER_URI',
    DOMAIN: 'BASE_URL_CC',

    KEY_CLOAK_CLIENT_ID: 'KEY_CLOAK_CLIENT_ID',
    KIBANA_UI_SERVICE_API_URL: 'http://mumchsem01:8200',
    // IMAGE_PATH: 'http://172.21.152.251:31004/content',
    IMAGE_PATH: 'https://customerconnect-dev.crisil.com/usermanagement/content',
    profile: 'prod'
};
