<div class="layout-content">
    <p-toast class="custom-toast"></p-toast>
    <div>
        <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="isSuccess == true">
            <i class="pi pi-check" style="font-size: 1em" aria-hidden="true"></i> {{ displaySuccessMessage }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isSuccess = false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="isFailure == true">
            <i class="pi pi-pi-exclamation-triangle" style="font-size: 1em" aria-hidden="true"></i> {{ displayFailureMessage }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isFailure = false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="p-formgrid p-grid">
        <div class="p-col-12 p-pl-0 p-pr-0">
            <div class="ui-fluid p-formgrid p-grid p-ai-center">
                <div class="p-col-12 p-sm-12 p-text-left p-pl-0">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb"
                            (onItemClick)="itemClicked($event)">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-formgrid p-grid">
        <div class="p-col-12 p-pl-0 p-pr-0">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12  p-pl-0 p-pr-0 p-text-left">
                    <div class="p-field p-mb-0">
                        <div>
                            <p-table *ngIf="columns && columns.length > 0" #dt [columns]="columns" [value]="records"
                                [lazy]="false" (onLazyLoad)="getUserDataByPage($event)" [paginator]="false"
                                [rowsPerPageOptions]="[10, 20, 40, 50, 60, 100]" [rows]="100" [loading]="loading"
                                [autoLayout]="true" [scrollable]="true" (onFilter)="tableFilter($event)"
                                styleClass="p-datatable-gridlines custom-scroll" class="p-vertical-table" [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [globalFilterFields]="['username','firstName','lastName','email']" id="p-table" scrollHeight="345px" [scrollable]="true">
                                <ng-template pTemplate="caption">
                                    <div class="p-d-flex w-100 p-jc-between left">
                                        <span class="p-input-icon-left p-px-0 p-col-3">
                                            <i class="pi pi-search"></i>
                                            <input class="p-w-100" pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains');"
                                            placeholder="Search">
                                        </span>
                                        <div class="p-col-3 p-text-right p-pr-0">
                                            <div class="btnwrap">
                                                <button class="p-button-primary" [disabled]="filteredUsername.length == 0" pButton label="Export" (click)="exportData()"></button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th id="dummyUser">User Name</th>
                                        <th id="dummyFirst">First Name</th>
                                        <th id="dummyLast">Last Name</th>
                                        <th id="dummyEmail">Email</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <td class="username" (click)="navigateToEdit(rowData)">{{rowData.username}}</td>
                                        <td>{{rowData.firstName}}</td>
                                        <td>{{rowData.lastName}}</td>
                                        <td>{{rowData.email}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <!-- <div class="no_data_div" *ngIf="!columns || columns.length == 0">
                                <div class="app-loading" id="loader">
                                    <div class="loadbg"></div>
                                    <div class="bdload">
                                        <div class="spinner">
                                            <div class="rect1"></div>
                                            <div class="rect2"></div>
                                            <div class="rect3"></div>
                                            <div class="rect4"></div>
                                            <div class="rect5"></div>
                                            <span>Customer Connect...</span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <p-dialog header="Confirm" [(visible)]="displayDelete" [style]="{ width: '50vw' }"
                            [baseZIndex]="10000">
                            <ng-template pTemplate="header">
                                Confirmation
                            </ng-template>
                            <div class="p-formgrid p-grid">
                                <div class=" p-col-12">
                                    <p>Are you sure?</p>
                                </div>
                            </div>
                            <ng-template pTemplate="footer">
                                <button pButton type="button" label="Yes" class="ui-button-raised"
                                    (click)="deleteDynamicUserData()"></button>
                                <button pButton type="button" label="No" (click)="discard()"
                                    class="ui-button-raised ui-button-danger"></button>
                            </ng-template>
                        </p-dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog header="User Details" class="previewModal mConfirmationPopup" [draggable]="false" [(visible)]="userDetailsFlag" [style]="{ width: '45vw' }" [modal]="true" name="udetails">
    <ng-template pTemplate="header">
        User Details
    </ng-template>
    <div class="p-formgrid p-grid">
        <div class="p-col-12 p-px-0">
            <p-card  styleClass="p-cardpadding-n">
                <div style="font-weight: bold;" class="userName">
                    {{selectedUser.firstName}} {{selectedUser.lastName}}
                </div>
                <div class="p-d-flex p-jc-between">
                    <div class="p-mt-2 p-d-flex p-flex-column">
                        <label>User Name: <span style="font-weight: bold;">{{selectedUser.username}}</span></label>
                        <label>Email: <span class="p-mt-2" style="font-weight: bold;">{{selectedUser.email}}</span></label>
                    </div>
                    <div class="p-mt-2 p-d-flex p-flex-column">
                        <label>Role: <span style="font-weight: bold;">{{userDetails.roleName}}</span></label>
                        <label>Permission: <span class="p-mt-2" style="font-weight: bold;">{{userDetails.permissionName}}</span></label>
                    </div>
                </div>
                <div class="p-mt-2">
                    <div class="p-mb-2" style="font-weight: bold;">Scope</div>
                    <div class="scopes">
                        <span *ngFor="let item of userDetails.scopeName" class="custom-chip">{{item}}</span>
                    </div>
                </div>
            </p-card>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" label="Cancel" (click)="userDetailsFlag=false;"
            class="p-button-brand2 p-mx-0"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="errorPopup" [modal]="true" [style]="{width: '55vw', height: '22vh'}"
    [draggable]="false" [resizable]="false">
    <p>Failed to connect with the server, Sorry for the inconvenience.</p>
</p-dialog>

<p-dialog header="Info" class="previewModal mConfirmationPopup" [(visible)]="noData" [modal]="true" [style]="{width: '27vw', height: '22vh'}"
    [draggable]="false" [resizable]="false">
    <p>No Roles or Scopes assigned to this User.</p>
</p-dialog>

<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
    <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-text-left">
        <div class="btnwrap">
            <button pButton class="p-button-brand2 p-button" (click)="backFunction()" type="button" label="Back">
            </button>
        </div>
    </div>
</div>