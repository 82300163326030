import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpContextToken } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppLoaderService } from '../../shared/util/app-loader.service';
import { finalize, tap } from 'rxjs/operators';
import { error } from 'console';

export const BYPASS_LOADER = new HttpContextToken(() => false);

@Injectable()
export class RequestLoaderInterceptor implements HttpInterceptor {
    constructor(private loaderService: AppLoaderService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        //Showing loader if loader is not bypassed while the API call
        if (!request.context.get(BYPASS_LOADER)) {
            this.loaderService.showPageLoader();
        }

        return next.handle(request).pipe(
            finalize(() => {
                //Hiding loader if loader is not bypassed while the API call
                if (!request.context.get(BYPASS_LOADER)) {
                    this.loaderService.hidePageLoader();
                }
            })
        );
    }
}
