import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoginService, AccountService, Account } from 'app/core';
import { HomeService } from './home.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

@Component({
    selector: 'jhi-home',
    templateUrl: './home.component.html',
    styleUrls: ['home.css']
})
export class HomeComponent implements OnInit {
    account: Account;
    displayFailureMessage: string;
    isFailure = false;
    displaySuccessMessage: string;
    isSuccess = false;

    records = [];
    recordsSub = [];
    scenarioId = 0;
    filters = {};
    scenarioCreated = 0;
    scenarioInProgress = 0;
    scenarioUnderReview = 0;
    scenarioRejected = 0;
    scenarioCompleted = 0;
    remainingScenarioCount = 0;
    riskFactorsCount = 0;
    isError: any;
    clientId: any;
    clientList: any;
    changeClient: any;
    modelRecords = [];
    modelRecordsSub = [];
    modelId = 0;
    modelFilters = {};
    modelCreated = 0;
    modelInProgress = 0;
    modelUnderReview = 0;
    modelRejected = 0;
    modelCompleted = 0;
    remainingModelsCount = 0;

    activityList = [];

    scenarioInProgressStatus = 'In Progress';
    scenarioUnderReviewStatus = 'Under Review';
    scenarioRejectedStatus = 'Rejected';
    scenarioCompletedStatus = 'Completed';
    scenarioInApprovalStatus = 'In Approval';

    @ViewChild('deleteDataModal')
    deleteDataModal: ElementRef;

    @ViewChild('deleteModelDataModal')
    deleteModelDataModal: ElementRef;

    constructor(
        private router: Router,
        private modalService: NgbModal,
        private accountService: AccountService,
        private loginService: LoginService,
        private homeService: HomeService,
        private location: Location
    ) {}

    ngOnInit() {
        //this.getHomePageInformation();
        /*   this.accountService.identity().then((account: Account) => {
            this.account = account;
        }); */
    }
}
