import { Route } from '@angular/router';

import { UserRouteAccessService } from 'app/core';
import { AdministrationComponent } from './administration.component';
import { ScopeGuard } from 'app/guards/scope.guard';

export const ADMINISTRATION_ROUTE: Route = {
    path: 'administrationHome',
    component: AdministrationComponent,
    data: {
        authorities: [],
        pageTitle: 'NexEra 2.0'
    },
    canActivate: [UserRouteAccessService, ScopeGuard]
};
