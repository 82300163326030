<div class="layout-content-user customheight">

    <div class="row">

        <div class="col-md-6">
            <h1 class="page-heading mt-2">

                <img src="../../content/images/svg/administrtion-heading.png" alt="sem-icons">
                <strong>UserManagement Dashboard </strong></h1>
            <label class="title text-dark">Select a task below</label>



        </div>

        
        <div class="row col-md-12 mt-5 pr-0">
        <div class="col-sm-3 mb-3" routerLink="/administrationHome/dashboard/onboard">
            <div class="card cri-cards">
                <div class=" d-flex flex-row admindashboardcard">
                    <div class="p-2" class="nav-link admindashboardtext"><strong>Application On-Board</strong></div>
                </div>
            </div>
        </div>
        <div class="col-sm-3 mb-3" routerLink="/Models/definition">
            <div class="card cri-cards">
                <div class=" d-flex flex-row admindashboardcard">
                    <div class="p-2" class="nav-link admindashboardtext"><strong>Models/definition On-Board</strong></div>
                </div>
            </div>
        </div>

        <div class="col-sm-3 mb-3" routerLink="/administrationHome/dashboard/configuration">
            <div class="card cri-cards">
                <div class=" d-flex flex-row admindashboardcard">
                    <div class="p-2" class="nav-link admindashboardtext"><strong>User Configuration</strong></div>
                </div>
            </div>
        </div>


        <div class="col-sm-3 mb-3" routerLink="/administrationHome/dashboard/creation">
            <div class="card cri-cards">
                <div class=" d-flex flex-row admindashboardcard">
                    <div class="p-2" class="nav-link admindashboardtext"><strong>User Creation</strong></div>
                </div>
            </div>
        </div>

        </div>
    </div>

</div>