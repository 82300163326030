import { Component } from '@angular/core';
import { ALERT_MSG_TIME_OUT } from 'app/constants';
import { DynamicUserService } from './dynamicUser.service';
import { NgForm } from '@angular/forms';
//import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { RoleScopeList } from './RoleScopeList.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOMAIN } from 'app/app.constants';

@Component({
    selector: 'user-creation',
    templateUrl: './userCreation.component.html',
    styleUrls: ['./userCreation.component.css']
})
export class UserCreationComponent {
    keyCloakUserName: string;
    keyCloakFirstName: string;
    keyCloakLastName: string;
    keyCloakEmail: string;
    isEditable: boolean = false;
    isFailure: boolean;
    isSuccess: boolean;
    displaySuccessMessage: string;
    displayFailureMessage: string;
    data: any[];
    userConfigList: any;
    configJSON: any;
    dropDownList: any;
    userDetailsObj = {
        appRoles: [],
        componentNames: [],
        componentRoles: [],
        selectedTreeData: []
    };
    applicationRoles: any[] = [];
    components: any[] = [];
    componentRoleList: any[] = [];
    testArray: any[] = [];
    updateId: any;
    selectedValue: any;
    userObjColumns: Object;
    field: any;
    ele_e: any;
    componentRoles: any;
    roleList: any;
    operation: boolean;
    componentIds: any[] = [];
    userId;
    ListArray = ['Product', 'Region', 'Sector', 'Entity'];
    fileData: File;
    filePathtoDownload: string;

    ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    selectedAccessLevel: any[] = [];
    treeDataValue: any[] = [];
    selectedFilters: any[] = [];
    accessLevelList: any[] = [];
    filtersTreeData: any;
    cols: any[] = [];
    accessFilterNames: any;
    selectedLevel;
    subordinateLevel: boolean = false;
    teamList: { label: string; value: number }[];
    teamFlag: boolean = false;
    selectedTeamObj = null;
    selectedGroupObj = null;
    selectedPermissionObj = null;
    treeTableData: TreeNode[];
    treeTableHeaderList = [];
    hierarchyData = [];
    rowData: any;
    getNodeChildDetails: any;
    columns: any[];
    selectedNode: TreeNode[] = [];
    deletedSubordinates = [];
    selectedNodeFilters: any[] = [];

    pager = {
        totalItems: null,
        currentPage: null,
        pageSize: null,
        totalPages: null,
        startPage: null,
        endPage: null,
        startIndex: null,
        endIndex: null,
        pages: null
    };

    isSubordinateReq: any[] = [];
    updatedFilterdata: any;
    isSubordinate = false;
    subordinateFlag: boolean = false;
    paginatorFlag = true;
    columnDefs: any;
    defaultColDef: any;
    gridApi: any;
    updateMode: false;
    groupFlag: boolean = false;
    permissionFlag: boolean = false;
    checkboxFlag = false;

    columnDefsForFilter: any;
    defaultColDefForFilter: any;
    gridApiForFilter: any;
    accessFilterData: any;
    getNodeChildDetailsForFilter: any;
    accessCheck: boolean = false;
    filterName: any;
    currentTabName: any;
    tabIndex = 0;
    selectedMasterIds: any[];
    responseMap: any;
    teamResponseMap: any;
    showGrid: boolean = true;
    groupResponseMap: any[];
    disableFlag: boolean = false;
    userType: any;
    roleBasedScopeList: any = [];
    //roleScopeList: roleScopeList[];
    roleScopeList: RoleScopeList = new RoleScopeList();
    displayScope = false;
    isLoad: boolean = true;

    constructor(
        private dynamicUserService: DynamicUserService,
        private _location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal
    ) {
        this.defaultColDefForFilter = {
            filter: 'agTextColumnFilter',
            resizable: true,
            filterParams: {
                filterOptions: ['contains'],
                suppressAndOrCondition: true
            },
            rowDrag: true,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                suppressCount: true,
                checkbox: true,
                editable: false,
                suppressDoubleClickExpand: true
            },
            suppressMenu: true,
            suppressRowClickSelection: true,
            pivot: false
        };
    }

    ngOnInit() {
        this.hideLoader();
        this.displayScope = false;
        this.disableFlag = false;
        this.tabIndex = 0;
        this.keyCloakUserName = this.route.snapshot.params.username;
        this.keyCloakFirstName = this.route.snapshot.params.firstname;
        this.keyCloakLastName = this.route.snapshot.params.lastname;
        this.keyCloakEmail = this.route.snapshot.params.email;

        this.responseMap = this.route.snapshot.params.responseMap;
        this.operation = true;
        let res = this.route.snapshot.params.responseMap;
        if (res != undefined) {
            this.responseMap = JSON.parse(res);
        }
        this.getNodeChildDetailsForFilter = function getNodeChildDetailsForFilter(rowItem) {
            if (rowItem.hierarhcyDtoList && rowItem.hierarhcyDtoList.length > 0) {
                return {
                    group: true,
                    children: rowItem.hierarhcyDtoList,
                    expanded: true,
                    key: rowItem.id
                };
            } else {
                return null;
            }
        };
        if (this.responseMap == undefined) {
            this.getAccessLevelForUser();
        } else {
            this.dynamicUserService.getAccessFiltersDetails().subscribe(response => {
                this.accessFilterNames = [];
                this.filtersTreeData = [];
                this.selectedFilters = [];
                this.filtersTreeData = response['responseMap'];
                this.accessFilterNames = response['ACCESS_FILTER_NAMES'];
                this.accessFilterNames.forEach(ele => {
                    let selectedFilter: any[] = [];
                    this.selectedFilters.push(selectedFilter);
                });
                this.columnDefsForFilter = [
                    {
                        headerName: 'Name',
                        field: 'name',

                        cellRendererParams: {
                            suppressCount: true,
                            checkbox: true,
                            suppressDoubleClickExpand: true
                        },
                        suppressMenu: true,
                        width: 350
                    }
                ];
                this.currentTabName = this.accessFilterNames[0];
                let list = [];
                list = Object.keys(this.responseMap);
                list.forEach(ele => {
                    this.filtersTreeData[ele] = this.responseMap[ele];
                    let data = this.responseMap[ele].filter(item => item.selected == true).map(item => item.id);
                    this.responseMap[ele].map(item => {
                        if (item.hierarhcyDtoList != null) {
                            item.hierarhcyDtoList.forEach(element => {
                                if (element.selected == true) {
                                    data.push(element.id);
                                }
                            });
                        }
                    });
                    this.accessFilterNames.forEach((item, i) => {
                        if (item == ele) {
                            this.selectedFilters[i] = data;
                        }
                    });
                    this.saveUserObj[ele] = data;
                });
            });
        }

        this.getApplicationRoles();
        this.getComponents();
        this.getTeams();
        this.getGroups();
        this.getPermission();
        this.getUserKeyCloakRole();
        this.getRoles();
        this.getScopes();
        this.getRoleScopeMappingList();
        this.getUserDetails(this.keyCloakUserName);

        this.defaultColDef = {
            filter: 'agTextColumnFilter',
            resizable: true,
            filterParams: {
                filterOptions: ['contains'],
                newRowsAction: 'keep',
                suppressAndOrCondition: true
            },
            rowDrag: true,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                suppressCount: true,
                checkbox: true,
                suppressDoubleClickExpand: true
            },
            suppressMenu: true,
            pivot: false
        };

        this.getNodeChildDetails = function getNodeChildDetails(rowItem) {
            if (rowItem.hierarhcyDtoList && rowItem.hierarhcyDtoList.length > 0) {
                return {
                    group: true,
                    children: rowItem.hierarhcyDtoList,
                    expanded: true,
                    key: rowItem.id
                };
            } else {
                return null;
            }
        };
    }

    getUserConfigData() {
        this.data = [];
        this.dynamicUserService.getUserConfigData().subscribe(
            response => {
                this.userConfigList = response['userConfigDataById'];
                this.configJSON = this.userConfigList['userConfigList'];
                this.data = this.configJSON;
                this.data.forEach(element => {
                    if (element.attributeType.lable == 'Mapping Type') {
                        const masterConfigDto = element.endpointUrl;

                        this.dynamicUserService.getSubRoles(masterConfigDto).subscribe(res => {
                            this.dropDownList = res;
                        });
                    }
                });
                if (this.userId != null) {
                    this.getUpdateUserData();
                }
            },

            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    getUpdateUserData() {
        this.data = [];
        this.operation = false;
        const obj = {};
        this.updateId = this.userId;
        obj['userId'] = this.userId;
        obj['configJSON'] = JSON.stringify(this.configJSON);
        this.treeTableData = [];
        this.selectedNode = [];

        this.dynamicUserService.getUserUpdateData(obj).subscribe(
            response => {
                this.data = response['CONFIG_JSON'];
                let details = response['APP_ROLES_DETAILS'];
                let level = response['SELECTED_ACCESS_LEVEL'];
                let teamId = response['TEAM_ID'];
                let groupId = response['GROUP_ID'];
                let permissionId = response['PERMISSION_ID'];
                let subordinate = response['IS_SUBORDINATE_REQ'];
                this.userType = response['USER_TYPE'];

                this.userDetailsObj.appRoles = details.appRoles;
                this.userDetailsObj.componentNames = details.appComponents;
                this.getComponentRoles(this.userDetailsObj.componentNames);
                this.userDetailsObj.componentRoles = details.appComponentRoles;

                this.selectedAccessLevel = [];
                this.selectedTeamObj = [];
                this.isSubordinateReq = [];

                this.selectedLevel = level;
                // for Team access level
                if (teamId != null && this.selectedLevel == 1) {
                    this.showGrid = false;
                    this.teamFlag = true;
                    const teamObj = new Object();
                    teamObj['id'] = teamId;
                    let filterTeamObj = this.teamArray.filter(item => item.value == teamId)[0];
                    this.selectedTeamObj = filterTeamObj;
                    this.getAccessFilterDataByTeamId(teamObj);
                }
                if (groupId != null && this.selectedLevel == 3) {
                    this.showGrid = false;
                    this.teamFlag = false;
                    this.groupFlag = true;
                    this.checkboxFlag = true;
                    const groupObj = new Object();
                    groupObj['id'] = groupId;
                    let filterTeamObj = this.groupArray.filter(item => item.value == groupId)[0];
                    this.selectedGroupObj = filterTeamObj;
                    this.getAccessFilterDataByGroupId(groupObj);
                } else if (permissionId != null && this.selectedLevel == 4) {
                    this.showGrid = false;
                    this.teamFlag = false;
                    this.groupFlag = false;
                    this.permissionFlag = true;
                    this.checkboxFlag = true;
                    const permissionObj = new Object();
                    permissionObj['id'] = permissionId;
                    let filterTeamObj = this.permissionArray.filter(item => item.value == permissionId)[0];
                    this.selectedPermissionObj = filterTeamObj;
                    this.getAccessFilterDataByGroupId(permissionObj);
                }

                if (subordinate != null && subordinate == 'N') {
                    this.isSubordinateReq = [];
                } else {
                    let subordinateValue = 3;
                    this.isSubordinateReq.push(subordinateValue);
                }

                if (subordinate != null && subordinate == 'Y') {
                    this.subordinateFlag = true;

                    this.hierarchyData = response['PARENTCHILDMAP'];
                    this.columnDefs = [
                        {
                            headerName: 'Suborainte Name',
                            field: 'name',

                            cellRendererParams: {
                                suppressCount: true,
                                checkbox: true,
                                suppressDoubleClickExpand: true
                            },
                            suppressMenu: true,
                            width: 350
                        }
                    ];

                    this.rowData = this.hierarchyData;
                } else {
                    this.subordinateFlag = false;
                }
                if (this.userType === 'INTERNAL') {
                    this.checkboxFlag = true;
                }
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    setNodeSelected = function(node, selectGroupName) {
        for (let j = 0; j < this.treeTableData.length; j++) {
            if (this.treeTableData[j].data.children && this.treeTableData[j].data.children.length > 0) {
                for (var i = 0; i < this.treeTableData[j].data.children.length; i++) {
                    this.setNodeSelected(this.treeTableData[j].data.children, selectGroupName);
                }
            } else {
                if (this.treeTableData[j].data.subordinate_name.trim().toLowerCase() === selectGroupName.trim().toLowerCase()) {
                    this.treeTableData[j].data.isSelected = true;
                }
            }
        }
    };

    onChangeComponent(component, event) {

        this.componentIds = this.userDetailsObj.componentNames;
        if (event.checked) {
            this.getComponentRoles(this.componentIds);
        } else {
            if (this.userDetailsObj.componentNames.length == 0) {
                this.componentRoleList = [];
                this.userDetailsObj.componentNames = [];
                return false;
            }
            let filteredRole = this.roleList.filter(p => p.componentId == component.value).map(p => p.roleId);
            filteredRole.forEach(ele => {
                const index: number = this.userDetailsObj.componentRoles.indexOf(ele);
                if (index != -1) {
                    this.userDetailsObj.componentRoles.splice(index, 1);
                }
            });
            this.getComponentRoles(this.componentIds);
        }
    }

    permissionName: any;
    scopeList: any;
    roleName: any;
    getUserDetails(username) {
        this.scopeList = [];
        this.dynamicUserService.getUserDetails(username).subscribe(response => {
            let data = response;
            if (data == null) {
                this.noScopeMapped = true;
            } else {
                this.noScopeMapped = false;
                this.roleName = data.roleName;
                this.scopeList = data.scopeName;
                this.permissionName = data.permissionName;
            }

            if (this.scopeList == null) {
                this.noScopeMapped = true;
            }
        });
    }

    getApplicationRoles() {
        this.dynamicUserService.getApplicationRoles().subscribe(
            response => {
                this.applicationRoles = response;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    getComponents() {
        this.dynamicUserService.getOnboardedComponents().subscribe(
            response => {
                this.components = response;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    getComponentRoles(componentIds) {
        this.componentRoleList = [];
        this.dynamicUserService.getComponentRoles(componentIds).subscribe(
            response => {
                this.roleList = response;
                this.roleList.forEach(ele => {
                    let object = new Object();
                    object['label'] = ele.name;
                    object['value'] = ele.roleId;
                    this.componentRoleList.push(object);
                });
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }
    saveUserObj = new Object();

    saveUser(form: NgForm) {
        if (this.selectedLevel == null) {
            this.showErrorValidations(true, 'Please select Access Level Team/Self.');
            return false;
        } else if (this.selectedLevel == '1' && this.selectedTeamObj == null) {
            this.showErrorValidations(true, 'Please select Team');
            return false;
        } else if (this.selectedLevel == '3' && this.selectedGroupObj == null) {
            this.showErrorValidations(true, 'Please select Group');
            return false;
        } else if (this.userDetailsObj.appRoles.length == 0) {
            this.showErrorValidations(true, 'Please select atleast one Application Role.');
            return false;
        }

        if (form.valid) {
            if (this.updateId != null) {
                this.saveUserObj['id'] = this.updateId;
                this.saveUserObj['isSubordinateReq'] = this.isSubordinateReq;
                if (this.selectedLevel == '2') {
                    this.accessFilterNames.forEach((ele, i) => {
                        this.saveUserObj[ele] = this.selectedFilters[i];
                    });
                }
            } else {
                this.saveUserObj['id'] = null;
                if (this.isSubordinateReq.length > 0) {
                    this.saveUserObj['isSubordinateReq'] = this.isSubordinateReq;
                } else {
                    this.saveUserObj['isSubordinateReq'] = [];
                }
            }

            const jsonObj = new Object();
            let filteredList = this.data.filter(
                p =>
                    p.attributeName != 'Username' &&
                    p.attributeName != 'Last Name' &&
                    p.attributeName != 'First Name' &&
                    p.attributeName != 'Email' &&
                    p.attributeName != 'Id'
            );

            filteredList.forEach(element => {
                jsonObj[element.attributeName] = element.columnValue;
            });

            if (this.selectedLevel == '1') {
                this.saveUserObj['teamId'] = this.selectedTeamObj.value;
            } else if (this.selectedLevel == '3') {
                this.saveUserObj['groupId'] = this.selectedGroupObj.value;
            }

            this.saveUserObj['accessLevel'] = this.selectedLevel;
            this.saveUserObj['configData'] = JSON.stringify(jsonObj);
            this.saveUserObj['appRoles'] = this.userDetailsObj.appRoles;
            this.saveUserObj['appComponents'] = this.userDetailsObj.componentNames;
            this.saveUserObj['appComponentRoles'] = this.userDetailsObj.componentRoles;
            this.saveUserObj['subOrdinates'] = this.userDetailsObj.selectedTreeData;
            this.saveUserObj['deletedSubordinates'] = this.deletedSubordinates;

            this.data.forEach((element, i) => {
                if (i < 4) {
                    this.field = element.attributeName;
                    let value = element.columnValue;
                    this.saveUserObj[this.field] = value;
                }
            });

            this.userObjColumns = this.saveUserObj;

            this.dynamicUserService.saveDynamicUserData(this.userObjColumns).subscribe(
                response => {
                    if (this.updateId != null) {
                        this.showSuccessValidations(true, 'Successfully updated data.');
                        this.router.navigate(['administrationHome/userCreationList', { massage: 'Successfully updated data.' }], {
                            skipLocationChange: true
                        });
                    } else {
                        this.showSuccessValidations(true, 'Successfully saved data.');
                        this.router.navigate(['administrationHome/userCreationList', { massage: 'Successfully saved data.' }], {
                            skipLocationChange: true
                        });
                    }

                    this.getUserConfigData();
                    form.resetForm();
                },
                response => {
                    this.showErrorValidations(true, response.error);
                }
            );
        }
    }

    showSuccessValidations(showMessage: boolean, displayValidationMessage: string) {
        this.isFailure = false;
        this.isSuccess = showMessage;
        document.documentElement.scrollTop = 0;
        this.displaySuccessMessage = displayValidationMessage;
        setTimeout(() => {
            this.isSuccess = false;
            this.displaySuccessMessage = '';
        }, ALERT_MSG_TIME_OUT);
    }

    showErrorValidations(showMessage: boolean, displayValidationMessage: string) {
        this.isSuccess = false;
        this.isFailure = showMessage;
        document.documentElement.scrollTop = 0;
        this.displayFailureMessage = displayValidationMessage;
        setTimeout(() => {
            this.isFailure = false;
            this.displayFailureMessage = '';
        }, ALERT_MSG_TIME_OUT);
    }

    onUpload(event) {
        if (event.target.files.length === 1) {
            const file: File = event.target.files[0];
            this.fileData = file;
            this.filePathtoDownload = file.name;
        }
    }

    selectTeam() {
        if (this.selectedLevel != null || this.selectedLevel != '') {
            this.teamFlag = true;
            this.groupFlag = false;
            this.selectedTeamObj = [];
        }
    }

    selectGroup() {
        if (this.selectedLevel != null || this.selectedLevel != '') {
            this.groupFlag = true;
            this.teamFlag = false;
            this.selectedGroupObj = [];
        }
    }

    selectPermission() {
        if (this.selectedLevel != null || this.selectedLevel != '') {
            this.groupFlag = false;
            this.teamFlag = false;
            this.permissionFlag = true;
            this.selectedPermissionObj = [];
        }
    }

    selectSelf() {
        this.checkboxFlag = false;
        this.showGrid = false;
        this.teamFlag = false;
        this.groupFlag = false;
        this.permissionFlag = false;
        this.disableFlag = false;
        this.selectedTeamObj = null;
        this.selectedGroupObj = null;
        this.userDetailsObj.appRoles = [];
        this.userDetailsObj.componentNames = [];
        this.componentRoleList = [];
        this.userDetailsObj.componentRoles = [];
        this.dynamicUserService.getAccessFiltersDetails().subscribe(response => {
            this.accessFilterNames = [];
            this.filtersTreeData = [];
            this.filtersTreeData = response['responseMap'];
            this.accessFilterNames = response['ACCESS_FILTER_NAMES'];
            this.showGrid = true;
            this.selectedFilters = [];
            this.accessFilterNames.forEach(ele => {
                let selectedFilter: any[] = [];
                this.selectedFilters.push(selectedFilter);
            });
        });
    }

    onChangeSubordinate(event) {
        if (event.checked) {
            this.saveUserObj['isSubordinateReq'] = 'Y';
            this.treeTableData = [];
            this.dynamicUserService.getSubOrdinatesData().subscribe(data => {
                this.subordinateFlag = true;
                this.treeTableHeaderList = data['TREEHEADERSLIST'];
                this.hierarchyData = data['PARENTCHILDMAP'];
                this.columnDefs = [
                    {
                        headerName: 'Suborainte Name',
                        field: 'name',
                        cellRendererParams: {
                            suppressCount: true,
                            checkbox: true,
                            suppressDoubleClickExpand: true
                        },
                        suppressMenu: true,
                        width: 350
                    }
                ];

                this.rowData = this.hierarchyData;
            });
            this.saveUserObj['isSubordinateReq'] = this.isSubordinateReq;
        } else {
            this.treeTableData = [];
            this.selectedNode = [];
            this.paginatorFlag = false;
            this.subordinateFlag = false;
            this.saveUserObj['isSubordinateReq'] = [];
        }
    }

    onGridReady(params) {
        if (this.subordinateFlag) {
            this.gridApi = params.api;
            this.gridApi.setRowData(this.rowData);
            this.gridApi.forEachNode(node => (node.data.selected == true ? node.setSelected(true) : node.setSelected(false)));
            this.gridApi.sizeColumnsToFit();
        } else {
            this.gridApi = params.api;
            params.api.sizeColumnsToFit();
        }
    }

    onRowSelected(event) {
        if (event.node.selected) {
            if (!this.userDetailsObj.selectedTreeData.includes(event.data.id)) {
                this.userDetailsObj.selectedTreeData.push(event.data.id);
            }
            if (event.data.hierarhcyDtoList && event.data.hierarhcyDtoList != undefined) {
                this.findChild(event.data.hierarhcyDtoList, false);
            }
        } else {
            if (this.userDetailsObj.selectedTreeData !== undefined && this.userDetailsObj.selectedTreeData.includes(event.data.id)) {
                this.deletedSubordinates.push(event.data.id);
                this.userDetailsObj.selectedTreeData = this.userDetailsObj.selectedTreeData.filter(x => x != event.data.id);

                if (event.data.hierarhcyDtoList && event.data.hierarhcyDtoList != undefined) {
                    this.findChild(event.data.hierarhcyDtoList, true);
                }
            }
        }
    }

    findChild(children, deleteFlag) {
        if (!deleteFlag) {
            children.forEach(ele => {
                this.userDetailsObj.selectedTreeData.push(ele.id);
                if (ele.hierarhcyDtoList && ele.hierarhcyDtoList !== undefined) {
                    this.findChild(ele.hierarhcyDtoList, deleteFlag);
                }
            });
        } else {
            children.forEach(ele => {
                if (this.userDetailsObj.selectedTreeData !== undefined && this.userDetailsObj.selectedTreeData.includes(ele.id)) {
                    this.deletedSubordinates.push(ele.id);
                    this.userDetailsObj.selectedTreeData = this.userDetailsObj.selectedTreeData.filter(x => x != ele.id);

                    if (ele.hierarhcyDtoList && ele.hierarhcyDtoList !== undefined) {
                        this.findChild(ele.hierarhcyDtoList, deleteFlag);
                    }
                }
            });
        }
    }

    onSelectionChanged(event) {
    }

    onTeamChange(event) {
        this.checkboxFlag = false;
        this.showGrid = false;
        this.isEditable = true;
        this.userDetailsObj.appRoles = [];
        this.userDetailsObj.componentNames = [];
        this.componentRoleList = [];
        this.userDetailsObj.componentRoles = [];
        if (event.value != null) {
            let teamId = event.value.value;
            const obj = new Object();
            obj['id'] = teamId;
            this.getAccessFilterDataByTeamId(obj);
        }
    }

    onGroupChange(event) {
        this.checkboxFlag = true;
        this.showGrid = false;
        this.selectedTeamObj = null;
        if (event.value != null) {
            let groupId = event.value.value;
            const obj = new Object();
            obj['id'] = groupId;
            this.getAccessFilterDataByGroupId(obj);
        }
    }

    getAccessFilterDataByTeamId(obj) {
        this.dynamicUserService.getAccessFiltersDetailsByTeam(obj).subscribe(
            response => {
                this.teamResponseMap = [];
                this.disableFlag = true;
                this.teamResponseMap = response['responseMap'];
                this.dynamicUserService.getAccessFiltersDetails().subscribe(res => {
                    this.accessFilterNames = [];
                    this.filtersTreeData = [];
                    this.filtersTreeData = res['responseMap'];
                    this.accessFilterNames = res['ACCESS_FILTER_NAMES'];
                    let list = [];
                    list = Object.keys(this.teamResponseMap);
                    list.forEach((ele, i) => {
                        this.filtersTreeData[ele] = this.teamResponseMap[ele];
                    });
                    this.showGrid = true;
                    this.checkboxFlag = false;
                });
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    getAccessFilterDataByGroupId(obj) {
        this.dynamicUserService.getAccessFiltersDetailsByGroup(obj).subscribe(
            response => {
                this.groupResponseMap = [];
                this.groupResponseMap = response['responseMap'];
                let details = response['APP_ROLES_DETAILS'];
                if (details != null) {
                    this.userDetailsObj.appRoles = details.appRoles;
                    this.userDetailsObj.componentNames = details.appComponents;
                    this.getComponentRoles(this.userDetailsObj.componentNames);
                    this.userDetailsObj.componentRoles = details.appComponentRoles;
                }
                this.dynamicUserService.getAccessFiltersDetails().subscribe(res => {
                    this.accessFilterNames = [];
                    this.filtersTreeData = [];
                    this.disableFlag = true;
                    this.filtersTreeData = res['responseMap'];
                    this.accessFilterNames = res['ACCESS_FILTER_NAMES'];
                    let list = [];
                    list = Object.keys(this.groupResponseMap);
                    list.forEach((ele, i) => {
                        this.filtersTreeData[ele] = this.groupResponseMap[ele];
                    });
                    this.showGrid = true;
                });
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    teamArray: any;
    getTeams() {
        this.dynamicUserService.getAllTeams().subscribe(
            response => {
                let data = response;
                this.teamArray = data;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }
    groupArray: any;
    getGroups() {
        this.dynamicUserService.getAllGroups().subscribe(
            response => {
                let data = response;
                this.groupArray = data;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    permissionArray: any;
    getPermission() {
        this.dynamicUserService.getAllPermissions().subscribe(
            response => {
                let data = response;
                this.permissionArray = data;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    userKeyCloakRoleArray: any;
    getUserKeyCloakRole() {
        const obj = {};
        obj['userId'] = this.userId;
        this.dynamicUserService.getUserKeyCloakRoles(obj).subscribe(
            response => {
                let data = response;
                this.userKeyCloakRoleArray = data;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    roleArray: any;
    getRoles() {
        this.dynamicUserService.getAllKeycloakRoles().subscribe(
            response => {
                let data = response;
                this.roleArray = data;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    scopeArray: any;
    getScopes() {
        this.dynamicUserService.getAllKeycloakScopes().subscribe(
            response => {
                let data = response;
                this.scopeArray = data;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    roleScopeListArray: any;
    getRoleScopeMappingList() {
        this.dynamicUserService.getRoleScopeMappingList().subscribe(response => {
            let data = response;
            this.roleScopeListArray = data;
        });
    }

    roleScopeIterate: [];
    noScopeMapped = false;
    onRoleSelection(param) {
        this.displayScope = true;
        this.roleScopeIterate = null;
        this.roleScopeListArray.forEach(element => {
            if (element.roleName == param) {
                this.roleScopeIterate = element.scopeList;
            }
        });
    }

    onTabChange(event) {
        this.currentTabName = this.accessFilterNames[event.index];
        this.tabIndex = event.index;
        if (!this.saveUserObj.hasOwnProperty(this.currentTabName)) {
            this.selectedMasterIds = [];
        }
    }

    onRowSelectedForFilter(event) {
        if (event.node.selected == true) {
            this.selectedFilters[this.tabIndex].push(event.node.data.id);
            this.selectedFilters[this.tabIndex] = this.selectedFilters[this.tabIndex].filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });
            this.saveUserObj[this.currentTabName] = this.selectedFilters[this.tabIndex];
        } else {
            if (this.selectedFilters[this.tabIndex] != null) {
                this.selectedFilters[this.tabIndex] = this.selectedFilters[this.tabIndex].filter(item => item !== event.node.data.id);
                this.saveUserObj[this.currentTabName] = this.selectedFilters[this.tabIndex];
            }
        }
    }

    onSelectionChangedForFilter(event) {
    }

    onGridReadyForFilter(params) {
        this.gridApiForFilter = params.api;
        this.gridApiForFilter.forEachNode(node => (node.data.selected == true ? node.setSelected(true) : node.setSelected(false)));
        if (this.disableFlag) {
            this.gridApiForFilter.forEachNode(node => (node.selectable = false));
        }
        params.api.sizeColumnsToFit();
    }
    getAccessLevelForUser() {
        this.dynamicUserService.getAccessFiltersDetails().subscribe(response => {
            this.accessFilterNames = [];
            this.filtersTreeData = [];
            this.selectedFilters = [];
            this.filtersTreeData = response['responseMap'];
            this.accessFilterNames = response['ACCESS_FILTER_NAMES'];
            this.accessFilterNames.forEach(ele => {
                let selectedFilter: any[] = [];
                this.selectedFilters.push(selectedFilter);
            });

            this.columnDefsForFilter = [
                {
                    headerName: 'Name',
                    field: 'name',

                    cellRendererParams: {
                        suppressCount: true,
                        checkbox: true,
                        suppressDoubleClickExpand: true
                    },
                    suppressMenu: true,

                    width: 350
                }
            ];
            this.currentTabName = this.accessFilterNames[0];
        });
    }

    hideLoader() {
        setTimeout(() => {
            this.isLoad = false;
        }, 3000);
    }

    backFunction() {
        this.router.navigate(['/administrationHome/userCreationList']);
    }
    ccprimebreadcrumbList = [
        { label: `Dashboard`, routerLink: [''] },
        { label: `Users`, routerLink: ['/administrationHome/userCreationList'] },
        { label: 'User View', routerLink: ['/administrationHome/userCreationList/userCreation'] }
    ];

    homeiconccprimebreadcrumb = { icon: 'pi pi-home' };
    itemClicked(event) {
        if (event.item.icon == 'pi pi-home') {
            sessionStorage.removeItem('urlRedirectedTo');
            sessionStorage.setItem('dashboard', 'true');
            window.location.href = `${DOMAIN}#/nexera/dashboard/home`;
        }
    }
}
