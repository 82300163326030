import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { errorRoute } from './layouts';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';

const LAYOUT_ROUTES = [...errorRoute];

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: 'admin',
                    loadChildren: () => import('./admin/admin.module').then(m => m.SemAdminModule)
                },
                { path: '', redirectTo: '/administrationHome', pathMatch: 'full'},
                { path: '**', component: EmptyRouteComponent, pathMatch: 'full' },

                ...LAYOUT_ROUTES
            ],
            { useHash: true }
        )
    ],
    exports: [RouterModule],
    providers: [{ provide: APP_BASE_HREF, useValue: '/' }]
})
export class SemAppRoutingModule {}
