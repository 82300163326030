import { UserRouteAccessService } from 'app/core';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { DynamicUserComponent } from './dynamicUser.component';
import { UserCreationComponent } from './userCreation.component';
import { AccessFilterComponent } from './accessFilter.component';
import { RoleScopeMappingComponent } from './roleScopeMapping.component';
import { UserManagementScopeGuard } from 'app/guards/usermanagementscope.guard';
import { RoleScopeGuard } from 'app/guards/rolescope.guard';

const routes: Routes = [
    {
        path: 'administrationHome/userCreationList',
        component: DynamicUserComponent,
        canActivate: [UserRouteAccessService, UserManagementScopeGuard],
        data: {
            pageTitle: 'NexEra 2.0'
        }
    },
    {
        path: 'administrationHome/userCreationList/userCreation',
        component: UserCreationComponent,
        canActivate: [UserRouteAccessService],
        data: {
            pageTitle: 'NexEra 2.0'
        }
    },
    {
        path: 'administrationHome/roleScopeMappingList/roleScopeMapping',
        component: RoleScopeMappingComponent,
        canActivate: [UserRouteAccessService, RoleScopeGuard],
        data: {
            pageTitle: 'NexEra 2.0'
        }
    },
    {
        path: 'administrationHome/accessFilters',
        component: AccessFilterComponent,
        canActivate: [UserRouteAccessService],
        data: {
            pageTitle: 'NexEra 2.0'
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DynamicUserRoutingModule {}
