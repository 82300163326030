import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { USER_MANAGEMENT_SERVER_API_URL } from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class DynamicUserService {
    constructor(private http: HttpClient) {}

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    getSubRoles(data): Observable<any> {
        return this.http.get(data);
    }

    /* getAllUsersData(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/clientUser/getAllUsersData', {});
    } */

    getAllUsersData(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/clientUser/getAllUsersData', {});
    }

    /* saveDynamicUserData(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/umMasterUser/addNewUser', obj);
    }

    deleteDynamicUserData(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/umMasterUser/deleteOneUser', obj);
    }

    getUserUpdateData(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/umMasterUser/getUserUpdateData', obj);
    }

    getUsersList(filter): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/umMasterUser/getUsersList', filter);
    }

    getUserConfigData(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/umMasterUser/getUserConfigData');
    } */

    saveDynamicUserData(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/umMasterUser/addNewUser', obj);
    }

    deleteDynamicUserData(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/umMasterUser/deleteOneUser', obj);
    }

    getUserUpdateData(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/umMasterUser/getUserUpdateData', obj);
    }

    getUsersList(filter): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/umMasterUser/getUsersList', filter);
    }

    getKeyCloakUserList(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/hrms/getUsersList');
    }

    exportUserData(payload): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/user/export', payload);
    }

    /* getKeyCloakUserList(): Observable<any>{
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getUserListMapping');
    }*/

    getUserKeyCloakRoles(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getUserRoleList', obj);
    }

    saveRoleScopeMapping(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/saveRoleScopeMapping', obj);
    }

    saveComponentRolePermissionMapping(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/saveComponentRolePermissionMapping', obj);
        //return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/component-mappings');
    }

    updateRoleScopeMapping(obbj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/updateRoleScopeMapping', obbj);
    }

    getUserConfigData(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/umMasterUser/getUserConfigData');
    }

    getApplicationRoles(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getApplicationRoles');
    }

    getRoleNameList(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getRoleNameList');
    }

    getComponenetWhiseRoles(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getComponenetWhiseRoles');
    }
    loadURLAccesses(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/loadURLAccesses');
    }

    saveOrUpdateURLAccess(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/saveOrUpdateURLAccess', obj, this.httpOptions);
    }

    extractSwagger(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/extractSwagger', obj, this.httpOptions);
    }

    getOnboardedComponents(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getOnboardedComponents');
    }

    getComponentRoles(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/component-roles', obj);
    }

    /* getAccessFiltersDetails(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/userAccess/getallDetails', {});
    }

    getFiltersMappedAppData(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/userAccess/getFiltersMappedAppData', {});
    } */

    getAccessFiltersDetails(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/userAccess/getallDetails', {});
    }

    getFiltersMappedAppData(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/userAccess/getFiltersMappedAppData', {});
    }

    /* getAllTeams() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/umMasterUser/getAllTeams');
    } */

    getAllTeams() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/umMasterUser/getAllTeams');
    }

    /* getAllGroups() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/group/getAllGroups');
    }

    getAllPermissions() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/group/getAllPermissions');
    } */

    getAllGroups() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/group/getAllGroups');
    }

    getAllPermissions() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/group/getAllPermissions');
    }

    getAllKeycloakRoles() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getRoles');
    }

    getAllKeycloakScopes() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getScopes');
    }

    getAllComponents() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/component-mappings');
    }

    getComponentRolePermissionMappingList() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/component-role-permission-mapping');
    }

    getPermissionList() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getPermissionList');
    }

    getAllKeycloakScopeRoleMapping() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getScopeRoleMapping');
    }

    getKeyCloakScopeList() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getKeyCloakScopeList');
    }

    getListOfRolesOnScopeSelection(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getListOfRolesForSelectedScope', obj);
    }

    getRoleScopeMappingList() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getRoleScopeMapping');
    }

    getRoleScopeObjectMappingList() {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/getRoleScopeMappingList');
    }

    /* getSubOrdinatesData(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/umMasterUser/getSubOrdinates', {});
    }
    getMappedResponse(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/umMasterUser/getMappedResponse', obj);
    } */

    getSubOrdinatesData(): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/umMasterUser/getSubOrdinates', {});
    }
    getMappedResponse(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/umMasterUser/getMappedResponse', obj);
    }

    /* getAccessFiltersDetailsByTeam(data) {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/team/getUpdateScenarioData', data);
    } */

    getAccessFiltersDetailsByTeam(data) {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/team/getUpdateScenarioData', data);
    }

    /* getAccessFiltersDetailsByGroup(data) {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/group/getGroupUpdateScenarioData', data);
    } */

    getAccessFiltersDetailsByGroup(data) {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/v1/usermanagement/group/getGroupUpdateScenarioData', data);
    }

    getDisplayScope(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getDisplayScope');
    }

    getScopeSelectedRole(data): Observable<any> {
        // return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getScopeSelectedRole?role=' + data);
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getScopeSelectedRole', { params: { role: data } });
    }

    saveRoleScopePermissionMapping(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/saveRoleScopePermissionMapping', obj);
    }

    getRoleScopePermission(): Observable<any> {
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getRoleScopePermission');
    }

    deleteRoleScopeFromKeycloak(obj): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVER_API_URL + 'api/user-management/deleteRoleScopeFromKeycloak', obj);
    }

    getUserDetails(data): Observable<any> {
        // return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getUserDetail?username=' + data);
        return this.http.get(USER_MANAGEMENT_SERVER_API_URL + 'api/getUserDetail', { params: { username: data } });
    }
}
