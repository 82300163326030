import { Component, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { DynamicUserService } from './dynamicUser.service';
import { DynamicUser } from './dynamicUser.model';
//import { JhiAlertService } from 'ng-jhipster';
import { KeycloakService } from 'keycloak-angular';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { PagerService } from 'app/pager.service';
import { ALERT_MSG_TIME_OUT } from 'app/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { DOMAIN } from 'app/app.constants';

@Component({
    selector: 'dynamicUser-app',
    templateUrl: './dynamicUser.component.html',
    styleUrls: ['./dynamicUser.component.css'],
    providers: [MessageService]
})
export class DynamicUserComponent {
    @ViewChild('createOrEditModal')
    createOrEditModal: ElementRef;

    public form: FormGroup;
    unsubcribe: any;
    isSuccess: any;
    displayFailureMessage: any;
    isFailure: any;
    dropDownList: [];
    fileData = null;
    filePathtoDownload = null;

    user: DynamicUser = new DynamicUser();
    selectedItem: any;
    selectMstTable: boolean;
    listingTabDiv: any;
    isarrow: boolean = false;
    pageSize = 10;

    filter = {
        filterDescrption: null,
        arguments: [],
        pageIndex: 1,
        pageSize: this.pageSize,
        sorts: [],
        userConfigId: null,
        currentTab: 'LIST',
        filterModels: {},
        sortMap: {},
        roleNames: [],
        recordStatus: '',
        appName: '',
        appValue: null,
        configJSON: null
    };
    userclients = false;
    displayBasic: boolean = false;
    countList: any[];
    levelList: any[];
    selectedValue: string;
    userList: any[];
    tempMasterList: any[];
    levelTypeJson: string;
    operation: boolean = false;
    dummy: any;

    columns = [];
    keyCloakColumns = [];
    headers = [];
    records: any;
    keyCloakUserList: any;
    totalSize: any;
    pager = {
        totalItems: null,
        currentPage: null,
        pageSize: null,
        totalPages: null,
        startPage: null,
        endPage: null,
        startIndex: null,
        endIndex: null,
        pages: null
    };
    data: any;
    loading: boolean;
    label: any;
    field: any;
    displayDelete: boolean;
    updateId: any;
    changeText: boolean;
    appliactionName: any;
    appName: any;
    configJSON: any;
    displaySuccessMessage: string;
    massage: any;
    userDetailsFlag: boolean = false;
    errorPopup: boolean = false;
    noData: boolean = false;
    userDetails: any = {};
    selectedUser: any = {};
    filteredUsername: any = [];

    constructor(
        private modalService: NgbModal,
        private _location: Location,
        private dynamicUserService: DynamicUserService,
       // private alertService: JhiAlertService,
        private router: Router,
        private pagerService: PagerService,
        private keycloakService: KeycloakService,
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private messageService: MessageService
    ) {
        this.loading = false;
    }

    userConfigList = [];
    allUsersList = [];
    ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    ngOnInit() {
        this.renderer.removeClass(document.body, 'overflow-scroll');
        this.massage = this.route.snapshot.params.massage;
        if (this.massage != null) {
            this.showSuccessValidations(true, this.massage);
        }
        this.getUsersData();
    }

    tableFilter(event) {
        this.filteredUsername = [];
        event.filteredValue.forEach(r => {
            this.filteredUsername.push(r.username);
        });
    }

    exportData() {
        let payload = { usernamelist: this.filteredUsername };
        this.dynamicUserService.exportUserData(payload).subscribe(
            res => {
                this.downloadFile(res.base64);
            },
            _err => {
                this.messageService.add({ severity: 'error', summary: 'Something went wrong!' });
            }
        );
    }

    downloadFile(file) {
        let src = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${file}`;
        const link = document.createElement('a');
        link.href = src;
        link.download = 'User Data';
        link.click();
        this.messageService.add({ severity: 'success', summary: 'Document downloaded successfully!' });
    }

    pageNo = 1;
    disableButtons: boolean;

    navigateToNewUser() {
        this.router.navigate(['administrationHome/userCreationList/userCreation'], { skipLocationChange: true });
    }

    navigateToEdit(data) {
        this.selectedUser = data;
        this.dynamicUserService.getUserDetails(this.selectedUser.username).subscribe(
            response => {
                if (response != null) {
                    this.userDetailsFlag = true;
                    this.noData = false;
                    this.userDetails = response;
                } else {
                    this.noData = true;
                }
            },
            _err => {
                this.errorPopup = true;
                this.noData = false;
            }
        );
    }

    showDetails(data) {
        const obj = new Object();
        obj['id'] = data.id;
        this.dynamicUserService.getMappedResponse(obj).subscribe(
            response => {
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    navigateToRoleScopeMapping() {
        this.router.navigate(['administrationHome/roleScopeMappingList/roleScopeMapping'], { skipLocationChange: true });
    }

    getUsersData() {
        this.data = [];
        this.dynamicUserService.getKeyCloakUserList().subscribe(
            response => {
                this.records = response['RECORDS'];
                this.columns = [
                    { field: 'username', header: 'User Name' },
                    { field: 'firstName', header: 'First Name' },
                    { field: 'lastName', header: 'Last Name' },
                    { field: 'email', header: 'Email' }
                ];
                this.totalSize = response['TOTAL_RECORDS'];
                this.filteredUsername = [];
                this.records.forEach(r => {
                    this.filteredUsername.push(r.username);
                });
                this.pageNo = Math.ceil(this.totalSize / this.pageSize);
                this.setPage(this.pageNo);
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    setPage(page) {
        // get pager object from service
        this.pager = this.pagerService.getPager(this.totalSize, page);

        if (page < 1 || page > this.pager.totalPages) {
            return;
        }
        // get current page of items
        if (this.pageNo == 1) {
            this.records = this.records.slice(this.pager.startIndex, this.pager.endIndex + 1);
        }
    }

    onUpload(event) {
        if (event.target.files.length === 1) {
            const file: File = event.target.files[0];
            this.fileData = file;
            this.filePathtoDownload = file.name;
        }
    }

    kam(event, columnName, ttt) {
    }

    getUserDataByPage(event: LazyLoadEvent) {
        //in a real application, make a remote request to load data using state metadata from event
        //event.first = First row offset
        //event.rows = Number of rows per page

        this.filter.pageIndex = event.first / event.rows + 1;
        this.filter.pageSize = event.rows;
        this.pageNo = event.first / event.rows + 1;
        this.getUsersData();
    }

    openDeleteDataModal(id) {
        this.displayDelete = true;
        this.updateId = id;
    }

    deleteDynamicUserData() {
        const obj = new Object();
        obj['appName'] = 'GAC User';
        obj['id'] = this.updateId;

        this.dynamicUserService.deleteDynamicUserData(obj).subscribe(
            response => {
                this.showSuccessValidations(true, 'Successfully deleted data.');
                this.updateId = null;
                this.getUsersData();
                this.displayDelete = false;
            },
           
        );
    }

    showSuccessValidations(showMessage: boolean, displayValidationMessage: string) {
        this.isFailure = false;
        this.isSuccess = showMessage;
        document.documentElement.scrollTop = 0;
        this.displaySuccessMessage = displayValidationMessage;
        setTimeout(() => {
            this.isSuccess = false;
            this.displaySuccessMessage = '';
        }, ALERT_MSG_TIME_OUT);
    }
    discard() {}
    showErrorValidations(showMessage: boolean, displayValidationMessage: string) {
        this.isSuccess = false;
        this.isFailure = showMessage;
        document.documentElement.scrollTop = 0;
        this.displayFailureMessage = displayValidationMessage;
        setTimeout(() => {
            this.isFailure = false;
            this.displayFailureMessage = '';
        }, ALERT_MSG_TIME_OUT);
    }

    backFunction() {
        this.router.navigate(['']);
    }

    ccprimebreadcrumbList = [
        { label: `Dashboard`, routerLink: [''] },
        { label: `Users`, routerLink: ['/administrationHome/userCreationList'] }
    ];

    homeiconccprimebreadcrumb = { icon: 'pi pi-home' };

    itemClicked(event) {
        if (event.item.icon == 'pi pi-home') {
            sessionStorage.removeItem('urlRedirectedTo');
            sessionStorage.setItem('dashboard', 'true');
            window.location.href = `${DOMAIN}#/nexera/dashboard/home`;
        }
    }
}
