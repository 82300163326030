import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';


import { SemSharedModule } from 'app/shared';
//import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { HOME_ROUTE } from './dashboard.route';

@NgModule({
    declarations: [DashboardComponent],
    imports: [
        SemSharedModule,
        //NgSelectModule,
        RouterModule.forChild(HOME_ROUTE)
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]


})
export class DashboardModule { }