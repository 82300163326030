import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DOMAIN } from 'app/app.constants';
import { CommonUtilsService } from 'app/shared/service/common-util.service';

@Injectable({
    providedIn: 'root'
})
export class ScopeGuard implements CanActivate {
    constructor(private router: Router, private commonUtils: CommonUtilsService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let scopeList = this.commonUtils.decrypt(localStorage.getItem('scopes'));
        let scopes = scopeList.split(',');
        if (scopes.indexOf('USERMANAGEMENT_LIST') != -1 || scopes.indexOf('USERMANAGEMENT_SCOPEMAPPING') != -1) {
            return true;
        } else {
            this.router.navigate([DOMAIN + '/#/nexera/dashboard/home']);
            return false;
        }
    }
}
